import Pusher from "pusher-js";

export default {
  subscribeAccounts(accounts, action) {
    // Enable pusher logging - don't include this in production
    Pusher.logToConsole = true;
    console.log(action);
    var pusher = new Pusher("1ff55ca0a6bcc1878e87", {
      cluster: "us2",
    });

    for (let i = 0; i < accounts.length; i++) {
      var channel = pusher.subscribe("account." + accounts[i].id);
      channel.bind("AccountUpdated", function (data) {
        console.log(action);
        action(data);
      });
    }
  },
};
