export default {
  getLoggedInUser() {
    return JSON.parse(window.localStorage.getItem("loggedInUser"));
  },
  setLoggedInUser(user) {
    window.localStorage.setItem("loggedInUser", user);
  },
  logout() {
    window.localStorage.removeItem("loggedInUser");
  },
};
