<template>
  <header class="header">
    <div><img src="../assets/logo-white.png" alt="logo" /></div>
    <nav>
      <div><router-link to="/">Home</router-link></div>
      <div><router-link to="/">About</router-link></div>
      <div>
        <router-link
          :to="
            '/graphs/' +
            this.$route.params.budgetId +
            '/' +
            this.$route.params.pageId
          "
          >Graphs</router-link
        >
      </div>
      <!-- <label v-if="isLoggedIn" for="">{{ getName() }}</label> -->
    </nav>
    <div class="user-links">
      <div v-if="!isLoggedIn"><router-link to="/login">Login</router-link></div>
      <div v-if="isLoggedIn">
        <router-link to="account">
          <img src="../assets/my-account-icon-white.png" />
        </router-link>
      </div>
      <div v-if="isLoggedIn">
        <div id="logout-btn" @click="logout">
          <img src="../assets/logout-icon-white.png" />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import StorageAdapter from "../tools/storageAdapter.js";

export default {
  name: "HeaderComponent",
  data() {
    return {
      isLoggedIn: false,
    };
  },
  mounted() {
    this.isLoggedIn = StorageAdapter.getLoggedInUser() != null;
  },
  methods: {
    getName() {
      if (this.isLoggedIn) {
        return StorageAdapter.getLoggedInUser().name;
      }
    },
    logout() {
      StorageAdapter.logout();
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss">
header {
  background: var(--accentColor);
  height: var(--headerHeight);
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding: 0 100px;

  img {
    max-height: var(--headerHeight);
  }

  nav {
    display: flex;
    align-items: center;
  }

  a {
    color: white;
    text-decoration: none;
    padding: 10px;
    font-size: 14pt;
  }

  a:hover {
    opacity: 0.6;
  }

  .user-links {
    display: flex;
    align-items: center;

    > div {
      margin-left: 20px;
    }

    //remove margin for first icon
    :first-child {
      margin-left: 0;
    }

    img {
      max-height: var(--headerIconHeight);
    }

    #logout-btn {
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }

    a {
      display: flex;
      align-items: center;
    }
  }
}
</style>
