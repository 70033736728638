<template>
  <div @click="toggle" class="section-toggler">
    <h1>{{ text }}</h1>
    <h1 class="icon">
      <span>{{ getIcon() }}</span>
    </h1>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isExpanded: false,
    };
  },
  props: {
    text: "",
  },
  methods: {
    toggle() {
      this.isExpanded = !this.isExpanded;
      this.$emit("toggle", this.isExpanded);
    },
    getIcon() {
      return this.isExpanded ? "-" : "+";
    },
  },
};
</script>

<style lang="scss">
.section-toggler {
  display: flex;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
  }

  &:first-child {
    background: red;
  }

  h1 {
    width: 50%;
    text-align: left;
    background-color: var(--lightGray);
    margin: 0;
    padding: 0 10px;
    // color: black;
    // text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
    //   1px 1px 0 #fff;
  }

  .icon {
    text-align: right;
  }
}
</style>
