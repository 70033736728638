<template>
  <div class="budget-adder-wrapper">
    <div>
      <label for="">Description :</label>
      <input type="text" v-model="budget.description" />
    </div>
    <div>
      <label for="">First page start date :</label>
      <input type="date" v-model="budget.start_date" />
    </div>
    <div>
      <label for="">First page end date :</label>
      <input type="date" v-model="budget.end_date" />
    </div>
    <div>
      <label for="">First page description :</label>
      <input type="text" v-model="budget.page_description" />
    </div>
    <button class="big-button" @click="addBudget">Add</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      budget: {},
    };
  },
  methods: {
    addBudget() {
      this.$emit("addBudget", this.budget);
    },
  },
};
</script>

<style lang="scss">
.budget-adder-wrapper {
  > div {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 50%;
    margin: 5px 0;

    > input {
      width: 100%;
    }
  }
}
</style>
