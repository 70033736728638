<template>
  <TransactionAdder
    :accountsProp="accountsProp"
    @addTransaction="addTransaction"
    :expensesProp="expensesProp"
  />
  <TransactionsViewer
    :transactionsProp="transactionsProp"
    :isAbsoluteProp="false"
    :accountsProp="accountsProp"
    :expensesProp="expensesProp"
    @editTransaction="editTransaction"
  />
</template>

<script>
import TransactionAdder from "./TransactionAdder.vue";
import TransactionsViewer from "./TransactionsViewer.vue";

export default {
  components: {
    TransactionAdder,
    TransactionsViewer,
  },
  data() {
    return {};
  },
  props: {
    accountsProp: [],
    expensesProp: [],
    transactionsProp: [],
  },
  methods: {
    addTransaction(transaction) {
      this.$emit("addTransaction", transaction);
    },
    editTransaction(transaction) {
      this.$emit("editTransaction", transaction);
    },
  },
};
</script>

<style lang="css"></style>
