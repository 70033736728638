<template>
  <h1>Expenses</h1>
  <div class="expense-adder-wrapper">
    <div>
      <label for="">Name : </label>
      <input type="text" v-model="expense.name" />
    </div>
    <div>
      <label for="">Description : </label>
      <input type="text" v-model="expense.description" />
    </div>
    <div>
      <button @click="addExpense">Add</button>
    </div>
  </div>
</template>

<script>
import Toolbox from "../../tools/toolbox";

export default {
  data() {
    return {
      expense: {},
    };
  },
  methods: {
    addExpense() {
      this.expense.budget_id = this.$route.params.budgetId;
      this.$emit("addExpense", Toolbox.cloneObject(this.expense));
      this.expense = {};
    },
  },
};
</script>

<style lang="scss">
.expense-adder-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    width: 80%;
    display: flex;
    margin: 2px 0;
    justify-content: center;

    &:last-child {
      justify-content: center;
    }

    > label {
      width: 30%;
    }
  }
}
</style>
