<template>
  <div class="page-selector-wrapper">
    <button
      v-for="page in pagesProp"
      :key="page.id"
      @click="goToPage(page.budget_id, page.id)"
    >
      {{ page.description }}
    </button>
    <button @click="toggleShowPageAdder" v-if="!showPageAdder">
      Add new page
    </button>
    <PageAdder
      @addedPage="addPage"
      :pagesProp="pagesProp"
      @closeForm="toggleShowPageAdder"
      v-if="showPageAdder"
    />
  </div>
</template>

<script>
import ApiAdapter from "../../tools/apiAdapter.js";
import PageAdder from "./PageAdder.vue";

export default {
  components: {
    PageAdder,
  },
  props: {
    amounts: [],
    pagesProp: [],
  },
  data() {
    return {
      showPageAdder: false,
    };
  },
  methods: {
    goToPage(budgetId, pageId) {
      this.$router.push("/budget/" + budgetId + "/" + pageId);
    },
    async addPage(pageId) {
      this.$emit("addedPage", pageId);
    },
    toggleShowPageAdder() {
      this.showPageAdder = !this.showPageAdder;
    },
  },
};
</script>

<style lang="scss">
.page-selector-wrapper {
  display: flex;
  width: 50%;
  overflow-x: scroll;

  button {
    background: red;
    border-radius: 0;
    background: var(--lightGray);
    border-left: 1px solid white;

    &:first-child {
      border-left: none;
    }
  }
}
</style>
