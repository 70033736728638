<template>
  <div @click="goToBudget" class="budget-viewer-wrapper">
    <p>{{ budget.description }}</p>
    <div><img src="../../assets/placeholder.png" alt="Placeholder" /></div>
  </div>
</template>

<script>
export default {
  props: {
    budget: Object,
  },
  methods: {
    goToBudget() {
      this.$router.push(
        "/budget/" + this.budget.id + "/" + this.budget.last_page_id
      );
    },
  },
};
</script>

<style lang="scss">
.budget-viewer-wrapper {
  background: var(--paleAccentColor);
  width: 100%;
  height: 300px;
  border: 5px solid transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  p {
    font-size: 20pt;
  }

  > div {
    height: 50%;
    width: 50%;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &:hover {
    cursor: pointer;
    border: 5px solid var(--accentColor);
  }
}
</style>
