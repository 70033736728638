<template>
  <div class="transaction-editor-wrapper">
    <div>
      <label for="transaction_date">Transaction date :</label>
      <input
        type="date"
        id="transaction_date"
        v-model="transaction.transaction_date"
      />
    </div>
    <div>
      <label for="amount">Amount :</label>
      <input type="number" id="amount" v-model="transaction.amount" />
    </div>
    <div>
      <label>Source</label>
      <AccountSelector
        :accountsProp="accountsProp"
        @selectedAccountChanged="sourceAccountChanged"
        :key="selectedSourceAccount"
        :selectedAccountIdProp="selectedSourceAccount.id"
      />
    </div>
    <div>
      <Label>Target</Label>
      <AccountSelector
        :accountsProp="accountsProp"
        @selectedAccountChanged="targetAccountChanged"
        :key="selectedTargetAccount"
        :selectedAccountIdProp="selectedTargetAccount.id"
      />
    </div>
    <div>
      <label for="">Expense</label>
      <ExpenseSelector
        @selectedExpenseChanged="selectedExpenseChanged"
        :expensesProp="expensesProp"
        :key="selectedExpense"
        :expenseIdProp="selectedExpense.id"
      />
    </div>
    <div>
      <button @click="editTransaction()">Save</button>
    </div>
  </div>
</template>

<script>
import AccountSelector from "../account/AccountSelector.vue";
import ExpenseSelector from "../expense/ExpenseSelector.vue";

export default {
  components: {
    AccountSelector,
    ExpenseSelector,
  },
  props: {
    tranProp: Object,
    accountsProp: [],
    expensesProp: [],
  },
  data() {
    return {
      transaction: this.tranProp,
      sourceAccount: {},
      targetAccount: {},
      expense: {},
      selectedExpense: {}, //Used only to update the expense selector component
      selectedTargetAccount: {}, //Used only to update the target account selector component
      selectedSourceAccount: {}, //Used only to update the source account selector component
    };
  },
  mounted() {
    this.targetAccountChanged(
      this.accountsProp.filter(
        (acc) => acc.id == this.tranProp.target_account_id
      )[0]
    );
    this.sourceAccountChanged(
      this.accountsProp.filter(
        (acc) => acc.id == this.tranProp.source_account_id
      )[0]
    );
    this.selectedExpenseChanged(
      this.expensesProp.filter((exp) => exp.id == this.tranProp.expense_id)[0]
    );
  },
  methods: {
    editTransaction() {
      this.transaction.source_account_id = this.sourceAccount.id;
      this.transaction.target_account_id = this.targetAccount.id;
      this.transaction.expense_id = this.expense.id;
      this.$emit("editTransaction", this.transaction);
    },
    sourceAccountChanged(account) {
      if (account) {
        this.sourceAccount = account;
        this.selectedSourceAccount.id = account.id;
      }
    },
    targetAccountChanged(account) {
      if (account) {
        this.targetAccount = account;
        this.selectedTargetAccount.id = account.id; //Necessary to update the component when we set it back to -1
        this.expense = {};
        this.selectedExpense.id = -1;
      }
    },
    selectedExpenseChanged(expense) {
      if (expense) {
        this.expense = expense;
        this.selectedExpense.id = expense.id; //Necessary to update the component when we set it back to -1
        this.targetAccount = {};
        this.selectedTargetAccount.id = -1;
      }
    },
  },
};
</script>

<style lang="scss">
.transaction-editor-wrapper {
  background: var(--paleAccentColor);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
