<template>
  <div class="expense-viewer-wrapper">
    <div>
      <!-- <label for="">Name : </label> -->
      <input type="text" v-model="expense.name" />
    </div>
    <div>
      <!-- <label for="">Description : </label> -->
      <input type="text" v-model="expense.description" />
    </div>
    <div>
      <button
        :disabled="hasChanged()"
        :class="canSaveClass()"
        @click="saveExpense"
      >
        Save
      </button>
    </div>
    <div>
      <button
        :disabled="expense.is_referenced"
        :class="canDeleteClass(expense.is_referenced)"
        @click="deleteExpense"
      >
        Delete
      </button>
    </div>
  </div>
</template>

<script>
import Toolbox from "../../tools/toolbox.js";

export default {
  props: {
    expenseProp: Object,
  },
  data() {
    return {
      expense: Toolbox.cloneObject(this.expenseProp),
      lastSavedExpense: Toolbox.cloneObject(this.expenseProp),
    };
  },
  methods: {
    saveExpense() {
      this.$emit("saveExpense", this.expense);
      this.lastSavedExpense = Toolbox.cloneObject(this.expense);
    },
    hasChanged() {
      return (
        JSON.stringify(this.expense) !== JSON.stringify(this.lastSavedExpense)
      );
    },
    deleteExpense() {
      this.$emit("deleteExpense", this.expense.id);
    },
    canSaveClass() {
      return this.hasChanged() ? "" : "disabled-button";
    },
    canDeleteClass(is_referenced) {
      return is_referenced ? "disabled-button" : "";
    },
  },
};
</script>

<style lang="scss">
.expense-viewer-wrapper {
  display: flex;
  align-items: center;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
</style>
