<template lang="">
  <div>
    <select v-model="selectedAccount" @change="onChange()" name="" id="">
      <option v-for="account in getUniqueAccounts()" :value="account">
        {{ account.description }}
      </option>
    </select>
  </div>
</template>
<script>
import Toolbox from "../../tools/toolbox.js";

export default {
  props: {
    accountsProp: [],
    selectedAccountIdProp: Number,
  },
  data() {
    return {
      selectedAccount: null,
    };
  },
  watch: {
    selectedAccountIdProp(value, oldValue) {
      //Reset component
      if (value == -1) this.selectedAccount = {};
      else if (value && this.accountsProp) {
        this.selectedAccount = Toolbox.cloneObject(
          this.accountsProp.find((acc) => acc.id == value)
        );
      }
    },
  },
  methods: {
    getUniqueAccounts() {
      let accounts = [];
      this.accountsProp.forEach((acc) => {
        if (accounts.filter((x) => x.id == acc.id).length == 0)
          accounts.push(acc);
      });
      return accounts;
    },
    onChange() {
      this.$emit("selectedAccountChanged", this.selectedAccount);
    },
  },
};
</script>
<style lang="css"></style>
