<template>
  <div class="chart-wrapper">
    <Pie :data="chartData" :options="options" />

    <div class="graph-filter-wrapper">
      <div v-for="filter in filters" :key="filter">
        <label for="">{{ filter.param }}</label>
        <input
          v-model="filter.value"
          :type="filter.type"
          @input="publishFilter(filter)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Colors,
} from "chart.js";
import { Pie } from "vue-chartjs";
import Toolbox from "../../tools/toolbox.js";

ChartJS.register(Tooltip, Legend, ArcElement, Colors);
export default {
  components: {
    Pie,
  },
  props: {
    labelsProp: [],
    valuesProp: [],
    filtersProp: [],
  },
  data() {
    return {
      chartData: {
        labels: this.labelsProp,
        datasets: [
          {
            // backgroundColor: [
            //   "#FF0000",
            //   "#0000FF",
            //   "#00008B",
            //   "#FFA500",
            //   "#800080",
            //   "#A52A2A",
            //   "#FFFF00",
            //   "#800000",
            //   "#00FF00",
            //   "#008000",
            //   "#FF00FF",
            //   "#808000",
            //   "#FFC0CB",
            //   "#7FFFD4",
            //   "#00FFFF",
            //   "#000000",
            //   "#ADD8E6",
            //   "#808080",
            //   "#C0C0C0",
            //   "#FFFFFF",
            // ],
            data: this.valuesProp,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
      },
      filters: this.filtersProp,
    };
  },
  methods: {
    publishFilter(filter) {
      this.$emit("filterValueChanged", filter);
    },
  },
};
</script>

<style lang="css"></style>
