<template>
  <div id="app">
    <HeaderComponent />
    <router-view />
  </div>
</template>

<script>
//import apiAdapter from './apiAdapter'
import HeaderComponent from "./components/HeaderComponent.vue";

export default {
  name: "App",
  components: {
    HeaderComponent,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">
:root {
  --accentColor: #4e9f3d;
  --paleAccentColor: #c2cba8;
  --lightGray: #5a5c5a;
  --headerHeight: 7vh;
  --headerIconHeight: 5vh;
  --contentHeight: 93vh;
}

* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
}

body {
  background: #383838;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #383838;
  // background: white;
  height: var(--contentHeight);

  .error {
    color: red;
  }

  table {
    border-spacing: 0;
  }

  td,
  th {
    padding: 0;
  }

  td > * {
    height: 30px;
    width: 100%;
  }
}

label,
h1,
h2,
h3,
h4,
td,
th {
  color: white;
}

button {
  background: var(--accentColor);
  color: white;
  padding: 5px;
  border-radius: 40px;
  border: 1px solid transparent;
  min-width: 100px;
  font-size: 12pt;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.big-button {
  min-width: 150px;
  font-size: 20pt;
  padding: 20px;
}

.disabled-button {
  background: var(--lightGray);
}
</style>
