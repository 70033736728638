<template>
  <div class="transactions-viewer-wrapper">
    <table
      :class="[
        isAbsoluteProp
          ? 'transactions-wrapper-absolute'
          : 'transactions-wrapper',
      ]"
    >
      <TransactionEditor
        v-if="showTransactionEditor"
        :tranProp="transactionToEdit"
        :accountsProp="accountsProp"
        :expensesProp="expensesProp"
        :key="transactionToEdit"
        @editTransaction="editTransaction"
      />

      <tr>
        <th>Source</th>
        <th>Amount</th>
        <th>Target</th>
        <th>Date</th>
        <th>Expense Name</th>
        <th>Amount description</th>
        <th></th>
      </tr>
      <tr v-for="tran in transactionsProp.filter(x => x.page_id == this.$route.params.pageId)">
        <td>{{ tran.Source_Description }}</td>
        <td>{{ tran.amount }}</td>
        <td>{{ tran.Target_Description }}</td>
        <td>{{ tran.transaction_date }}</td>
        <td>{{ tran.Expense_Name }}</td>
        <td>{{ tran.Amount_Description }}</td>
        <td>
          <Button class="edit-btn" @click="editButtonClick(tran)">Edit</Button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import TransactionEditor from "./TransactionEditor.vue";

export default {
  components: {
    TransactionEditor,
  },
  props: {
    transactionsProp: [],
    isAbsoluteProp: Boolean,
    accountsProp: [],
    expensesProp: [],
  },
  data() {
    return {
      showTransactionEditor: false,
      transactionToEdit: {},
    };
  },
  methods: {
    editButtonClick(tran) {
      this.showTransactionEditor = true;
      this.transactionToEdit = tran;
    },
    editTransaction(tran) {
      this.$emit("editTransaction", tran);
      this.showTransactionEditor = false;
    },
  },
};
</script>

<style lang="scss">
.transactions-viewer-wrapper {
  max-height: 50vh;
  overflow-y: auto;

  .transactions-wrapper-absolute {
    background-color: gray;
    width: 20vw;
    height: 20vh;
    position: absolute;
    top: 40vh;
    left: 40vw;
    overflow: scroll;
    z-index: 100;
  }

  .transactions-wrapper {
    border-collapse: collapse;
    width: 100%;

    th {
      background: transparent;
    }

    td {
      border: 1px solid black;
      background: white;
      color: black;
    }
  }

  .edit-btn {
    border-radius: 0;
    color: black;
    height: auto;
    padding: 0;
  }
}
</style>
