<template>
  <div class="amount-viewer-wrapper">
    <input type="text" v-model="amount.description" />
    <input type="number" v-model="amount.amount" />
    <select name="recurrentExpenseType" v-model="amount.type">
      <option value="EXPENSE-ONETIME">Expense</option>
      <option value="EXPENSE-WEEKLY">Weekly expense</option>
      <option value="EXPENSE-BI-MONTHLY">Bi-weekly expense</option>
      <option value="EXPENSE-MONTHLY" :selected="selected">
        Mensual expense
      </option>
      <option value="EXPENSE-ANNUAL">Annual expense</option>
      <option value="INCOME">Income</option>
      <option value="REFUND">Refund</option>
    </select>
    <input type="text" v-model="amount.comment" />
    <ExpenseSelector
      v-if="showExpenseSelector()"
      @selectedExpenseChanged="selectedExpenseChanged"
      :key="amount"
      :expensesProp="expensesProp"
      :expenseIdProp="amount.expense_id"
    />
    <button @click="save" v-if="hasChanged()">Save</button>
  </div>
</template>

<script>
import Toolbox from "../../tools/toolbox.js";
import ExpenseSelector from "../expense/ExpenseSelector.vue";

export default {
  components: {
    ExpenseSelector,
  },
  props: {
    amountProp: Object,
    expensesProp: [],
  },
  data() {
    return {
      amount: Toolbox.cloneObject(this.amountProp),
      lastSavedAmount: Toolbox.cloneObject(this.amountProp),
    };
  },
  methods: {
    save() {
      this.$emit("saveAmount", this.amount);
      this.lastSavedAmount = Toolbox.cloneObject(this.amount);
    },
    hasChanged() {
      return (
        JSON.stringify(this.amount) !== JSON.stringify(this.lastSavedAmount)
      );
    },
    selectedExpenseChanged(expense) {
      this.amount.expense_id = expense.id;
    },
    showExpenseSelector() {
      return this.amount.type.toUpperCase() == "EXPENSE-ONETIME";
    },
  },
};
</script>

<style lang="css">
.amount-viewer-wrapper {
  display: grid;
  grid-template-columns: 22.5% 22.5% 22.5% 22.5% 10%;
  /* margin-bottom: 10px; */
}

.amount-viewer-wrapper h3 {
  border: solid black 1px;
  margin: 0;
}
</style>
