<template>
  <div class="home-wrapper">
    <PageSelector @addedPage="addPage" :amounts="amounts" :pagesProp="pages" />
    <div class="budget-wrapper">
      <!-- First column  -->
      <div>
        <AmountsSection
          :amountsProp="amounts"
          :accountsProp="accounts"
          :expensesProp="sortedExpenses()"
          :key="amounts.length"
          @addAmount="addAmount"
          @saveAmount="saveAmount"
        />

        <TransactionsSection
          :accountsProp="accounts"
          :transactionsProp="getTransactionsOfCurrentPage()"
          :expensesProp="expenses"
          @addTransaction="addTransaction"
          @editTransaction="editTransaction"
        />
      </div>
      <!-- Second column -->
      <div>
        <AccountAdder @addAccount="addAccount" />

        <SectionToggler :text="'Primary'" @toggle="toggleShowPrimaryAccounts" />
        <AccountViewer
          v-if="showPrimaryAccounts"
          @saveAccount="saveAccount"
          v-for="account in getPrimaryAccounts()"
          :key="account.total"
          :accountProp="account"
          :transactionsProp="getTransactions(account.id)"
        />

        <SectionToggler
          :text="'Expenses'"
          @toggle="toggleShowExpenseAccounts"
        />
        <AccountViewer
          v-if="showExpenseAccounts"
          @saveAccount="saveAccount"
          v-for="account in getExpenseAccounts()"
          :key="account.total"
          :accountProp="account"
          :transactionsProp="getTransactions(account.id)"
        />

        <ExpenseAdder @addExpense="addExpense" />
        <div class="expenses-viewers-wrapper">
          <div>
            <ExpenseViewer
              v-for="expense in sortedExpenses()"
              :key="expense"
              :expenseProp="expense"
              @saveExpense="saveExpense"
              @deleteExpense="deleteExpense"
            />
          </div>
        </div>
      </div>
      <div>
        <SummaryGrid :key="transactions" :summaryProp="summary" />
      </div>
    </div>
  </div>
  <LoadingComponent v-if="isLoading" />
</template>

<script>
import SummaryGrid from "../components/SummaryGrid.vue";
import AmountsSection from "@/components/amount/AmountsSection.vue";
import AccountAdder from "../components/account/AccountAdder.vue";
import AccountViewer from "../components/account/AccountViewer.vue";
import AccountSelector from "../components/account/AccountSelector.vue";
import LoadingComponent from "../components/LoadingComponent.vue";
import PageSelector from "../components/page/PageSelector.vue";
import TransactionsSection from "@/components/transaction/TransactionsSection.vue";
import ExpenseAdder from "../components/expense/ExpenseAdder.vue";
import ExpenseViewer from "../components/expense/ExpenseViewer.vue";
import SectionToggler from "@/components/SectionToggler.vue";

import ApiAdapter from "../tools/apiAdapter.js";
import BroadcastAdapter from "../tools/broadcastAdapter";
import { watch } from "vue";
import { useRoute } from "vue-router";

//import Toolbox from "./toolbox.js"

export default {
  name: "HomePage",
  components: {
    SummaryGrid,
    AmountsSection,
    AccountAdder,
    AccountViewer,
    AccountSelector,
    LoadingComponent,
    PageSelector,
    TransactionsSection,
    ExpenseAdder,
    ExpenseViewer,
    SectionToggler,
  },
  data() {
    return {
      amounts: [],
      accounts: [],
      transactions: [],
      pages: [],
      expenses: [],
      summary: [],
      isLoading: false,
      showPrimaryAccounts: false,
      showExpenseAccounts: false,
    };
  },
  watch: {
    async $route(value, oldValue) {
      if (value) {
        this.isLoading = true;
        this.summary = await ApiAdapter.getSummary(this.$route.params.pageId);
        this.isLoading = false;
      }
    },
  },
  async mounted() {
    this.loadData();
  },
  async updated() {},
  methods: {
    toggleShowPrimaryAccounts() {
      this.showPrimaryAccounts = !this.showPrimaryAccounts;
    },
    toggleShowExpenseAccounts() {
      this.showExpenseAccounts = !this.showExpenseAccounts;
    },
    async callApi() {},
    async loadData() {
      this.isLoading = true;
      this.amounts = await ApiAdapter.getAmounts(this.$route.params.budgetId);
      this.accounts = await ApiAdapter.getAccounts(this.$route.params.budgetId);
      BroadcastAdapter.subscribeAccounts(this.accounts, this.accountUpdated);
      this.transactions = await ApiAdapter.getTransactions(
        this.$route.params.budgetId
      );
      this.pages = await ApiAdapter.getPages(this.$route.params.budgetId);
      this.expenses = await ApiAdapter.getExpenses(this.$route.params.budgetId);
      this.summary = await ApiAdapter.getSummary(this.$route.params.pageId);
      this.isLoading = false;
    },
    async addPage(pageId) {
      await this.loadData();
      this.$router.push(
        "/budget/" + this.$route.params.budgetId + "/" + pageId
      );
    },
    // goToPage(pageId) {
    //   //TODO:Check if this function is used
    //   this.$router.push("/budget/1/" + pageId);
    // },
    async addAmount(amount) {
      this.amounts.push(amount);
      let tran_id = (await ApiAdapter.addAmount(amount)).transaction_id;
      let tran = await ApiAdapter.getTransaction(tran_id);
      console.log(tran);
      if (tran != null) this.transactions.push(tran);

      //refresh expenses to update the is_referenced field
      this.expenses = await ApiAdapter.getExpenses(this.$route.params.budgetId);

      //TODO:create a function
      //update accounts total
      let targetAccount = this.accounts.filter(
        (acc) => acc.id == tran.target_account_id && acc.page_id == tran.page_id
      )[0];
      let sourceAccount = this.accounts.filter(
        (acc) => acc.id == tran.source_account_id && acc.page_id == tran.page_id
      )[0];
      if (targetAccount) targetAccount.total += tran.amount;
      if (sourceAccount) sourceAccount.total -= tran.amount;

      this.summary = await ApiAdapter.getSummary(this.$route.params.pageId);
    },
    async saveAmount(amount) {
      this.amounts = this.amounts.filter((exp) => exp.id != amount.id);
      this.amounts.push(amount);
      console.log(amount);
      let tran_id = (await ApiAdapter.editAmount(amount)).transaction_id;
      console.log(tran_id);
      let tran = await ApiAdapter.getTransaction(tran_id);
      console.log(tran);
      if (tran != null) this.transactions.push(tran);

      //refresh expenses to update the is_referenced field
      this.expenses = await ApiAdapter.getExpenses(this.$route.params.budgetId);

      //TODO:create a function
      //update accounts total
      let targetAccount = this.accounts.filter(
        (acc) => acc.id == tran.target_account_id && acc.page_id == tran.page_id
      )[0];
      let sourceAccount = this.accounts.filter(
        (acc) => acc.id == tran.source_account_id && acc.page_id == tran.page_id
      )[0];
      targetAccount.total += tran.amount;
      sourceAccount.total -= tran.amount;
    },
    async addAccount(account) {
      let id = await ApiAdapter.addAccount(account);
      if (id != null) {
        account.id = id;
        this.accounts.push(account);
      }
    },
    saveAccount(account) {
      this.accounts = this.accounts.filter((acc) => acc.id != account.id);
      this.addAccount(account);
    },
    getPrimaryAccounts() {
      return this.accounts.filter(
        (account) =>
          account.page_id == this.$route.params.pageId && account.is_primary
      );
    },
    getExpenseAccounts() {
      return this.accounts.filter(
        (account) =>
          account.page_id == this.$route.params.pageId && !account.is_primary
      );
    },
    getTransactions(accountId) {
      return this.transactions.filter(
        (tran) =>
          (tran.source_account_id == accountId ||
            tran.target_account_id == accountId) &&
          tran.page_id <= this.$route.params.pageId
      );
    },
    async getSummary() {},
    getTransactionsOfCurrentPage() {
      return this.transactions.filter(
        (tran) => tran.page_id == this.$route.params.pageId
      );
    },
    async addTransaction(transaction) {
      let id = await ApiAdapter.addTransaction(transaction);
      if (id != -1) {
        let transaction = await ApiAdapter.getTransaction(id);
        this.transactions.push(transaction);

        //TODO:Create a function
        //update related accounts
        let targetAccount = this.accounts.filter(
          (acc) =>
            acc.id == transaction.target_account_id &&
            acc.page_id == transaction.page_id
        )[0];
        let sourceAccount = this.accounts.filter(
          (acc) =>
            acc.id == transaction.source_account_id &&
            acc.page_id == transaction.page_id
        )[0];

        if (targetAccount) targetAccount.total += transaction.amount;
        if (sourceAccount) sourceAccount.total -= transaction.amount;
      }
    },
    async editTransaction(transaction) {
      let id = await ApiAdapter.editTransaction(transaction);
      if (id != null) {
        //Refresh target, source names
        let newTran = await ApiAdapter.getTransaction(id);
        this.transactions = this.transactions.filter((tran) => tran.id != id);
        this.transactions.push(newTran);
      }
    },
    async addExpense(expense) {
      let id = await ApiAdapter.addExpense(expense);
      if (id) {
        expense.id = id;
        this.expenses.push(expense);
      }
    },
    async saveExpense(expense) {
      let success = await ApiAdapter.editExpense(expense);
      if (success) {
        this.expenses = this.expenses.filter((exp) => exp.id != expense.id);
        this.expenses.push(expense);
      }
    },
    async deleteExpense(expenseId) {
      let success = await ApiAdapter.deleteExpense(expenseId);
      if (success) {
        this.expenses = this.expenses.filter((exp) => exp.id != expenseId);
      }
    },
    sortedExpenses() {
      return this.expenses.sort((element, next) =>
        element.name.localeCompare(next.name)
      );
    },
    accountUpdated(account) {
      console.log(account);
    },
  },
};
</script>

<style lang="scss">
#app {
  .home-wrapper {
    display: flex;
    flex-direction: column;

    .budget-wrapper {
      display: flex;
      column-gap: 3vw;

      /*> div{
        width : 40%;
      }*/

      > div:nth-child(3) {
        width: 20%;
      }
    }
  }
}

.expenses-viewers-wrapper {
  max-height: 40vh;
  height: 30vh;
  overflow-y: auto;
  overflow-x: initial;
}

.redFont {
  color: red;
}

.greenFont {
  color: green;
}
</style>
