<template>
  <div class="home-page-wrapper">
    <div class="budget-viewers">
      <BudgetViewer
        v-for="budget in budgets"
        :budget="budget"
        :key="budget.id"
      />
    </div>
    <div class="budget-adder-wrapper">
      <h3 v-if="!showBudgetAdder">
        Need to add a new budget ?<br />Click the link bellow
      </h3>
      <BudgetAdder v-if="showBudgetAdder" @addBudget="addBudget" />
      <button
        class="big-button"
        v-if="!showBudgetAdder"
        @click="toggleBudgetAdder"
      >
        Add
      </button>
    </div>
    <LoadingComponent v-if="isLoading" />
  </div>
</template>

<script>
import StorageAdapter from "../tools/storageAdapter.js";
import apiAdapter from "../tools/apiAdapter.js";
import BudgetViewer from "../components/budget/BudgetViewer.vue";
import LoadingComponent from "../components/LoadingComponent.vue";
import BudgetAdder from "../components/budget/BudgetAdder.vue";

export default {
  components: {
    BudgetViewer,
    LoadingComponent,
    BudgetAdder,
  },
  data() {
    return {
      budgets: [],
      isLoading: false,
      showBudgetAdder: false,
    };
  },
  async mounted() {
    // if (StorageAdapter.getLoggedInUser() == null) {
    //   this.$router.push("/login");
    // }
    this.isLoading = true;
    let budgets = await apiAdapter.getBudgets();
    if (budgets !== false) this.budgets = budgets;
    this.isLoading = false;
  },
  methods: {
    async addBudget(budget) {
      //create new budget and first page
      let informations = await apiAdapter.addBudget(budget);
      if (informations) {
        budget.id = informations.budget_id;
        budget.first_page_id = informations.page_id;
        this.budgets.push(budget);
      }
    },
    toggleBudgetAdder() {
      this.showBudgetAdder = !this.showBudgetAdder;
    },
  },
};
</script>

<style lang="scss">
.home-page-wrapper {
  display: flex;

  .budget-viewers {
    width: 50%;
    height: var(--contentHeight);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min-content, 300px));
    grid-gap: 20px;
    align-content: center;
    justify-content: center;
  }

  .budget-adder-wrapper {
    width: 50%;
    height: var(--contentHeight);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
