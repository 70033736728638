export default {
  normalizeExpenseToWeekly(amount, type) {
    if (type == null) type = "Expense-weekly";
    switch (type.toUpperCase()) {
      case "EXPENSE-BI-MONTHLY":
        return amount / 2;
      case "EXPENSE-MONTHLY":
        return (amount * 12) / 52;
      case "EXPENSE-ANNUAL":
        return amount / 52;
      default:
        return amount;
    }
  },
  normalizeExpenseToMonthly(amount, type) {
    if (type == null) type = "Expense-monthly";
    switch (type.toUpperCase()) {
      case "EXPENSE-WEEKLY":
        return (amount * 52) / 12;
      case "EXPENSE-BI-MONTHLY":
        return (amount * 26) / 12;
      case "EXPENSE-ANNUAL":
        return amount / 12;
      default:
        return amount;
    }
  },
  normalizeTransferToWeekly(amount, type) {
    if (type == null) type = "Transfer-weekly";
    switch (type.toUpperCase()) {
      case "Transfer-BI-MONTHLY":
        return amount / 2;
      case "Transfer-MONTHLY":
        return (amount * 12) / 52;
      case "Transfer-ANNUAL":
        return amount / 52;
      default:
        return amount;
    }
  },
  cloneObject(obj) {
    return JSON.parse(JSON.stringify(obj));
  },
  padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, "0");
  },
  createStandardDate(date) {
    date = new Date(date);
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      12,
      0,
      0,
      0
    );
  },
  getThemeColors() {
    return ["#4E9F3D", "#383838"];
  },
};
