<template>
  <div class="login-page-wrapper">
    <h2>Enter your email and password to access your budgets.</h2>
    <form>
      <label v-if="loginFailed" class="error">Wrong email or password</label>
      <div v-if="currentStep == 1">
        <label for="email">Email : </label>
        <input type="text" id="email" v-model="user.email" />
      </div>
      <div v-if="currentStep == 2">
        <label for="password">Password : </label>
        <input type="password" id="password" v-model="user.password" />
      </div>
      <button v-if="currentStep == 1" class="big-button" @click="currentStep++">
        Next
      </button>
      <button v-if="currentStep == 2" class="big-button" @click="login">
        Submit
      </button>
    </form>
    <router-link to="/register">New Customer ? Register</router-link>
  </div>
</template>

<script>
import ApiAdapter from "../tools/apiAdapter.js";

export default {
  name: "LoginPage",
  data() {
    return {
      user: {},
      loginFailed: false,
      currentStep: 1,
    };
  },
  methods: {
    async login() {
      let value = await ApiAdapter.login(this.user);
      this.loginFailed = !value;
      if (value) {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style lang="scss">
.login-page-wrapper,
.register-page-wrapper {
  height: var(--contentHeight);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  h2 {
    margin: 0;
    width: 25%;
  }

  form {
    border: solid 1px white;
    padding: 100px;

    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 15px 0;
    }

    button {
      padding: 10px;
    }

    input {
      height: 30px;
    }
  }

  a {
    color: white;
  }
}
</style>
