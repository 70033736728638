<template>
  <div class="graphs-wrapper">
    <div>
      <ExpensesChart :rawDataProp="expenses" :graphTypeProp="'BAR-CHART'" />
    </div>
    <div>
      <MonthlyStats :rawDataProp="monthlyStats" :graphTypeProp="'LINE-CHART'" />
    </div>
    <div>
      <MonthlyStats :rawDataProp="monthlyStats" :graphTypeProp="'BAR-CHART'" />
    </div>
    <div>
      <MonthlyDiffs :rawDataProp="monthlyDiffs" :graphTypeProp="'BAR-CHART'" />
    </div>
    <div>
      <ExpensesChart :rawDataProp="expenses" :graphTypeProp="'PIE-CHART'" />
    </div>
  </div>
</template>

<script>
import ApiAdapter from "../tools/apiAdapter";
import ExpensesChart from "../components/graphs/ExpensesChart.vue";
import MonthlyStats from "@/components/graphs/MonthlyStats.vue";
import MonthlyDiffs from "@/components/graphs/MonthlyDiffs.vue";

export default {
  name: "GraphsPage",
  components: {
    ExpensesChart,
    MonthlyStats,
    MonthlyDiffs,
  },
  data() {
    return {
      expenses: [],
      monthlyStats: [],
      monthlyDiffs: [],
      refreshChartIncrement: 0,
    };
  },
  async mounted() {
    this.expenses = await ApiAdapter.getGraphsExpenses(
      this.$route.params.budgetId
    );
    this.monthlyStats = await ApiAdapter.getMonthlyStats(
      this.$route.params.budgetId
    );
    this.monthlyDiffs = await ApiAdapter.getMonthlyDiffs(
      this.$route.params.budgetId
    );
  },
};
</script>

<style lang="scss">
.graphs-wrapper {
  width: 80vw;
  height: 80vh;
  margin: 10vh 0 0 10vw;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50% 50%;
  row-gap: 150;
  column-gap: 150;

  > * {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.chart-wrapper {
  width: 100%;
  height: 100%;
  display: flex;

  canvas {
    max-width: 80%;
    background: white;
  }

  .graph-filter-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 20%;

    > div {
      margin-bottom: 10px;
    }
  }
}
</style>
