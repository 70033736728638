import { createRouter, createWebHashHistory } from "vue-router";
import BudgetPage from "../views/BudgetPage.vue";
import LoginPage from "../views/LoginPage.vue";
import RegisterPage from "../views/RegisterPage.vue";
import HomePage from "../views/HomePage.vue";
import GraphsPage from "../views/GraphsPage.vue";
import StorageAdapter from "../tools/storageAdapter.js";

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
  },
  {
    path: "/budget/:budgetId/:pageId",
    name: "BudgetPage",
    component: BudgetPage,
  },
  {
    path: "/login",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/graphs/:budgetId/:pageId",
    name: "GraphsPage",
    component: GraphsPage,
  },
  {
    path: "/register",
    name: "RegisterPage",
    component: RegisterPage,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from) => {
  if (
    StorageAdapter.getLoggedInUser() == null &&
    to.name !== "LoginPage" &&
    to.name !== "RegisterPage"
  ) {
    return "/login";
  }
});

export default router;
