<template>
  <div class="loading">
    <div class="animation">Loading</div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.loading{
    width : 20vw;
    height : 20vh;
    position : absolute;
    top : 40vh;
    left : 40vw;
    background : rgb(127,127,127);
    
    .animation{
        margin-inline: auto;
    }
}

</style>