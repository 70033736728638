<template>
  <div class="page-adder-wrapper">
    <div class="page-adder-contour">
        <div class="input-wrapper">
            <label for="">Description :</label>
            <input id="newPageDescription" v-model="newPage.description" type="text">
        </div>
        <div class="input-wrapper">
            <label for="newPageStartDate">Start date :</label>
            <div>
                <span v-if="!validateStartDate()" class="error">The selected date does not continue your previous page</span>
                <input id="newPageStartDate" type="date" v-model="newPage.start_date">
            </div>
        </div>
        <div class="input-wrapper">
            <label for="newPageEndDate">End date : </label>
            <div>
                <span v-if="!validateEndDate()" class="error">The end date must be at least one day after your start date.</span>
                <input id="newPageEndDate" type="date" v-model="newPage.end_date">
            </div>
        </div>
        <button :disabled="!validateData()" @click="addPage">Ajouter</button>
        <span @click="closeForm" class="top-corner-close">X</span>
    </div>
  </div>
</template>

<script>
import Toolbox from "../../tools/toolbox.js"
import ApiAdapter from "../../tools/apiAdapter.js"

export default {
    props:{
        pagesProp : []
    },
    data(){
        return{
            newPage : {
                "description" : "",
                "start_date" : this.getNewStartDate(),
                "end_date" : this.getNewStartDate()
            }
        }
    },
    mounted(){
        
    },
    methods : {
        async addPage(){
            let pageId = await ApiAdapter.addPage(this.$route.params.budgetId, this.$route.params.pageId, this.newPage);
            if(pageId != null){
                this.closeForm();
                this.$emit('addedPage', pageId);
            }
        },
        closeForm(){
            this.$emit('closeForm');
        },
        getNewStartDate(){
            let start_date = Toolbox.createStandardDate(this.pagesProp[this.pagesProp.length - 1].end_date);
            let start_date_string = 
                Toolbox.padWithLeadingZeros(start_date.getUTCFullYear(), 2) + "-" + 
                Toolbox.padWithLeadingZeros(start_date.getUTCMonth() + 1, 2) + "-" + 
                Toolbox.padWithLeadingZeros((start_date.getUTCDate() + 1), 2);
            return start_date_string
        },
        validateStartDate(){
            let wantedStartDate = Toolbox.createStandardDate(this.pagesProp[this.pagesProp.length - 1].end_date);;
            wantedStartDate.setDate(wantedStartDate.getUTCDate() + 1);
            let currentStartDate = Toolbox.createStandardDate(this.newPage.start_date)   
            return wantedStartDate.getTime() == currentStartDate.getTime()
        },
        validateEndDate(){
            return Toolbox.createStandardDate(this.newPage.start_date) < Toolbox.createStandardDate(this.newPage.end_date);
        },
        validateData(){
            return (this.validateStartDate() && this.validateEndDate() && this.newPage.description != "");
        }
    }
}
</script>

<style lang="scss">
.page-adder-wrapper{
    position : absolute;
    top : 50%;
    left : 50%;
    background : gray;
    display : flex;
    flex-direction: column;
    transform: translate(-50%, -50%);

    .page-adder-contour{
        position : relative;
        padding : 30px;

        .input-wrapper{
            display : flex;
            justify-content: space-between;
            align-items: flex-end;

            div{
                display : flex;
                flex-direction: column;
                
                .error{
                    color : red;
                }
            }
        }

        .top-corner-close{
            position : absolute;
            right : 5px;
            top : 0px;
            font-weight: 700;
            font-size : 25px;
        }

        .top-corner-close:hover{
            cursor: pointer;
        }
    }
}
</style>