<template>
  <h1>Accounts</h1>
  <table>
    <tr>
      <th>Description</th>
      <th>Starting amount</th>
      <th>Is default</th>
      <th>Is primary</th>
    </tr>
    <tr>
      <td>
        <input v-model="description" type="text" />
      </td>
      <td>
        <input v-model="base_amount" type="number" />
      </td>
      <td>
        <input type="checkbox" v-model="is_default_account" />
      </td>
      <td>
        <input type="checkbox" v-model="is_primary" />
      </td>
      <td>
        <button @click="addAccount">Ajouter</button>
      </td>
    </tr>
  </table>
</template>

<script>
import { useRoute } from "vue-router";
import { ref } from "vue";

export default {
  data() {
    return {
      description: "",
      base_amount: 0,
      is_default_account: 0,
      is_primary: 1,
    };
  },
  setup() {
    const page_id = ref(useRoute().params.pageId);
    const budget_id = ref(useRoute().params.budgetId);
    return {
      page_id,
      budget_id,
    };
  },
  methods: {
    addAccount() {
      this.$emit("addAccount", {
        budget_id: this.budget_id,
        page_id: this.page_id,
        is_default_account: this.is_default_account,
        is_primary: this.is_primary,
        description: this.description,
        base_amount: this.base_amount,
      });
      this.description = "";
      this.base_amount = 0;
      this.is_default_account = 0;
    },
  },
};
</script>

<style lang="css"></style>
