<template>
  <div class="amount-adder-wrapper">
    <span @click="close()" class="close-button">X</span>
    <div>
      <label for="input-description">Description</label>
      <input id="input-description" type="text" v-model="description" />
    </div>
    <div>
      <label for="input-amount">Amount</label>
      <input id="input-amount" type="number" v-model="amount" />
    </div>
    <div>
      <label for="input-expenseType">Type</label>
      <select id="input-expenseType" name="recurrentExpenseType" v-model="type">
        <option value="EXPENSE-ONETIME">Expense</option>
        <option value="EXPENSE-WEEKLY">Expense weekly</option>
        <option value="EXPENSE-BI-WEEKLY">Expense bi-weekly</option>
        <option value="EXPENSE-MONTHLY" :selected="selected">
          Expense Monthly
        </option>
        <option value="EXPENSE-ANNUAL">Expense annual</option>
        <option value="INCOME">Income</option>
        <option value="REFUND">Refund</option>
      </select>
    </div>
    <div>
      <label for="input-comment">Comment</label>
      <input id="input-comment" type="text" v-model="comment" />
    </div>
    <div v-if="showExpenseSelector()">
      <label for="input-description">Expense type</label>
      <ExpenseSelector
        @selectedExpenseChanged="selectedExpenseChanged"
        :expensesProp="expensesProp"
      />
    </div>
    <div>
      <label>Source account</label>
      <AccountSelector
        :accountsProp="accountsProp"
        @selectedAccountChanged="sourceAccountChanged"
      />
    </div>
    <div>
      <label for="input-transaction_date">Transaction date</label>
      <input
        id="input-transaction_date"
        type="date"
        v-model="transaction_date"
      />
    </div>
    <div>
      <button @click="addAmount">Add</button>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRoute } from "vue-router";
import ExpenseSelector from "../expense/ExpenseSelector.vue";
import AccountSelector from "../account/AccountSelector.vue";

export default {
  components: {
    ExpenseSelector,
    AccountSelector,
  },
  props: {
    expensesProp: [],
    accountsProp: [],
  },
  data() {
    return {
      description: "",
      amount: 0,
      type: "EXPENSE-ONETIME",
      expense_id: null,
      comment: "",
      account_id: "",
      transaction_date: new Date().toISOString().split("T")[0],
    };
  },
  setup() {
    // const page_id = ref(useRoute().params.pageId);
    // const budget_id = ref(useRoute().params.budgetId);
    // return {
    //   page_id,
    //   budget_id,
    // };
  },
  methods: {
    addAmount() {
      this.$emit("addAmount", {
        budget_id: this.$route.params.budgetId,
        page_id: this.$route.params.pageId,
        description: this.description,
        amount: this.amount,
        type: this.type,
        expense_id: this.expense_id,
        source_account_id: this.account_id,
        transaction_date: this.transaction_date,
        comment: this.comment /*id : Math.floor(Math.random() * 1001)*/,
      });
      this.description = "";
      this.amount = 0;
      this.expense_id = null;
      this.comment = "";
      this.type = "EXPENSE-ONETIME";
      this.account_id = "";
      this.transaction_date = new Date();
      document.querySelector("#input-description").focus();
    },
    showExpenseSelector() {
      return (
        this.type.toUpperCase() == "EXPENSE-ONETIME" ||
        this.type.toUpperCase() == "REFUND"
      );
    },
    selectedExpenseChanged(expense) {
      this.expense_id = expense.id;
    },
    sourceAccountChanged(account) {
      this.account_id = account.id;
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
.amount-adder-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: gray;
  padding: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    margin: 5px 0;
  }

  label {
    width: 150px;
    display: inline-block;
    text-align: left;
  }

  select,
  input {
    width: 200px;
  }

  .close-button {
    position: absolute;
    top: 5px;
    left: 100%;
    transform: translate(-150%, 0);
    font-size: 20pt;
  }

  .close-button:hover {
    cursor: pointer;
  }
}
</style>
