<template lang="">
  <h1>Transactions</h1>
  <div class="transaction-adder-wrapper">
    <div>
      <label>Source account</label>
      <AccountSelector
        :accountsProp="accountsProp"
        @selectedAccountChanged="sourceAccountChanged"
      />
    </div>
    <div>
      <label>Target account</label>
      <AccountSelector
        :accountsProp="accountsProp"
        @selectedAccountChanged="targetAccountChanged"
        :key="selectedTargetAccount"
        :selectedAccountIdProp="selectedTargetAccount.id"
      />
    </div>
    <div>
      <label for="">Expense</label>
      <ExpenseSelector
        @selectedExpenseChanged="selectedExpenseChanged"
        :expensesProp="expensesProp"
        :key="selectedExpense"
        :expenseIdProp="selectedExpense.id"
      />
    </div>
    <div>
      <label for="amount-input">Amount</label>
      <input id="amount-input" v-model="transaction.amount" type="number" />
    </div>
    <div>
      <button :disabled="!canAdd()" @click="addTransaction()">Add</button>
    </div>
  </div>
</template>
<script>
import AccountSelector from "../account/AccountSelector.vue";
import ExpenseSelector from "../expense/ExpenseSelector.vue";

export default {
  props: {
    accountsProp: [],
    expensesProp: [],
  },
  data() {
    return {
      transaction: {},
      sourceAccount: {},
      targetAccount: {},
      expense: {},
      selectedExpense: {}, //Used only to update the expense selector component
      selectedTargetAccount: {}, //Used only to update the target account selector component
    };
  },
  components: {
    AccountSelector,
    ExpenseSelector,
  },
  methods: {
    addTransaction() {
      this.transaction.budget_id = this.$route.params.budgetId;
      this.transaction.page_id = this.$route.params.pageId;
      this.transaction.source_account_id = this.sourceAccount.id;
      this.transaction.target_account_id = this.targetAccount.id;
      this.transaction.expense_id = this.expense.id;
      this.$emit("addTransaction", this.transaction);
      this.resetComponentData();
    },
    sourceAccountChanged(account) {
      this.sourceAccount = account;
    },
    targetAccountChanged(account) {
      this.targetAccount = account;
      this.selectedTargetAccount.id = account.id; //Necessary to update the component when we set it back to -1
      this.expense = {};
      this.selectedExpense.id = -1;
    },
    selectedExpenseChanged(expense) {
      this.expense = expense;
      this.selectedExpense.id = expense.id; //Necessary to update the component when we set it back to -1
      this.targetAccount = {};
      this.selectedTargetAccount.id = -1;
    },
    resetComponentData() {
      this.transaction = {};
      this.sourceAccount = {};
      this.targetAccount = {};
      this.expense = {};
      this.selectedTargetAccount.id = -1;
      this.selectedExpense.id = -1;
    },
    canAdd() {
      return (
        this.sourceAccount.id && (this.targetAccount.id || this.expense.id)
      );
    },
  },
};
</script>
<style lang="scss">
.transaction-adder-wrapper {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 10%;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;

    select,
    div {
      /*for the selectors*/
      width: 100%;
      height: 100%;
    }

    button {
      margin-left: 20px;
    }
  }
}
</style>
